<template>
  <BRow>
    <BCol
      sm="6"
      lg="4"
    >
      <BCard class="mb-0">
        <BRow>
          <BCol cols="9">
            <div>
              <h3>0</h3>
              <p class="mb-0">
                Текущие задачи
              </p>
            </div>
          </BCol>
          <BCol cols="3">
            <div class="w-100 d-flex justify-content-end">
              <BBadge
                class="d-flex justify-content-center align-items-center rounded-circle"
                style="width: 3rem; height: 3rem"
                variant="success"
              >
                <feather-icon
                  class="w-100 h-100"
                  icon="ListIcon"
                />
              </BBadge>
            </div>
          </BCol>
        </BRow>
      </BCard>
    </BCol>
    <BCol
      sm="6"
      lg="4"
    >
      <BCard class="mb-0">
        <BRow>
          <BCol cols="9">
            <div>
              <h3>0</h3>
              <p class="mb-0">
                Доступные задачи
              </p>
            </div>
          </BCol>
          <BCol cols="3">
            <div class="w-100 d-flex justify-content-end">
              <BBadge
                class="d-flex justify-content-center align-items-center rounded-circle"
                style="width: 3rem; height: 3rem"
                variant="info"
              >
                <feather-icon
                  class="w-100 h-100"
                  icon="ListIcon"
                />
              </BBadge>
            </div>
          </BCol>
        </BRow>
      </BCard>
    </BCol>
    <BCol lg="4">
      <div class="w-100 h-100 d-flex justify-content-end align-items-center">
        <BButton variant="info">
          Получить новые задачи
        </BButton>
      </div>
    </BCol>
  </BRow>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BBadge,
    BButton,
  },
}
</script>
