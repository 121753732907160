<template>
  <div class="mt-2">
    <BCard>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <BFormCheckbox
            v-model="allSelected"
          >
            <!--            @change="selectAll"-->
            Выбрать все
          </BFormCheckbox>
        </div>
        <div>
          <BButton variant="outline-info">
            Завершить выбранные
          </BButton>
        </div>
      </div>
    </BCard>
    <BCard>
      <div class="d-flex align-items-center">
        <div class="mr-1">
          <BButton
            size="sm"
            style="padding: 5px"
            @click="collapseTaskState = !collapseTaskState"
          >
            <feather-icon
              :icon="collapseTaskState ? 'MinusIcon' : 'PlusIcon'"
            />
          </BButton>
        </div>
        <div class="mr-1">
          <BFormCheckbox> Текущие задачи</BFormCheckbox>
        </div>
        <div
          class="
            border-success
            rounded-circle
            d-flex
            align-items-center
            justify-content-center
          "
          style="width: 3rem; height: 3rem"
        >
          {{ casesList.count }}
        </div>
      </div>

      <BCollapse
        v-model="collapseTaskState"
        style="margin: 0 2.85rem"
      >
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <BFormCheckbox> Рассмотреть дело</BFormCheckbox>
          </div>
          <div>Без крайнего срока</div>
          <div>
            <BButton
              variant="success"
              class="mr-1"
            >Начать учет времени
            </BButton>
            <BButton variant="success">
              Завершить
            </BButton>
          </div>
        </div>
        <div
          v-for="(item, index) in casesList.results"
          :key="index"
          class="d-flex align-items-center mb-1"
        >
          <div class="mr-1">
            <BButton
              style="padding: 2px"
              class="rounded-circle mr-50"
            >
              <feather-icon
                icon="PlusIcon"
              />
            </BButton>
            <span>
              Должник
            </span>
          </div>
          <div>
            <BButton
              class="mr-1 border"
              variant="light"
            >
              {{ item.BORROWER.FULL_NAME }}
            </BButton>
            <BButton
              class="border"
              variant="light"
              @click="pushUserDetail(item.id)"
            >
              Переход к делу
            </BButton>
          </div>
        </div>
      </BCollapse>
    </BCard>
    <BCard>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <BFormCheckbox
            v-model="allSelected"
          >
            <!--            @change="selectAll"-->
            Выбрать все
          </BFormCheckbox>
        </div>
        <div>
          <BButton variant="outline-info">
            Завершить выбранные
          </BButton>
        </div>
      </div>
    </BCard>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BFormCheckbox,
  BCollapse,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'TaskList',
  components: {
    BCard,
    BFormCheckbox,
    BButton,
    BCollapse,
  },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      allSelected: false,
      collapseTaskState: false,
    }
  },
  computed: {
    ...mapState('cases', ['casesList']),

    getUserId() {
      return useJwt.getUserData()
    },
  },
  mounted() {
    this.FETCH_SK_DEBT({ MANAGER: this.getUserId.id })
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_DEBT']),

    pushUserDetail(id) {
      this.$router.push(`/case-card/${id}`)
    },
  },
}
</script>
