<template>
  <div>
    <Statistics />
    <TaskList :tasks="[]" />
  </div>
</template>
<script>
import Statistics from '../components/Statistics.vue'
import TaskList from '../components/TaskList.vue'

export default {
  components: {
    Statistics,
    TaskList ,
  },
}
</script>
